// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-insights-js": () => import("./../../../src/components/insights.js" /* webpackChunkName: "component---src-components-insights-js" */),
  "component---src-components-insights-page-js": () => import("./../../../src/components/insights-page.js" /* webpackChunkName: "component---src-components-insights-page-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-api-js": () => import("./../../../src/pages/api.js" /* webpackChunkName: "component---src-pages-api-js" */),
  "component---src-pages-emailconfirmation-js": () => import("./../../../src/pages/emailconfirmation.js" /* webpackChunkName: "component---src-pages-emailconfirmation-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-invitationconfirmation-js": () => import("./../../../src/pages/invitationconfirmation.js" /* webpackChunkName: "component---src-pages-invitationconfirmation-js" */),
  "component---src-pages-passwordresetfailure-js": () => import("./../../../src/pages/passwordresetfailure.js" /* webpackChunkName: "component---src-pages-passwordresetfailure-js" */),
  "component---src-pages-passwordresetsuccess-js": () => import("./../../../src/pages/passwordresetsuccess.js" /* webpackChunkName: "component---src-pages-passwordresetsuccess-js" */),
  "component---src-pages-policy-js": () => import("./../../../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-resetpasswordnotification-js": () => import("./../../../src/pages/resetpasswordnotification.js" /* webpackChunkName: "component---src-pages-resetpasswordnotification-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

